/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface TrackPurchaseRequest
 */
export interface TrackPurchaseRequest {
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'quizUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'orderCountry': string;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'plansSlug'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrackPurchaseRequest
     */
    'preorder': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrackPurchaseRequest
     */
    'freeTrial': boolean;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrackPurchaseRequest
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof TrackPurchaseRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseRequest
     */
    'time': string;
}
/**
 * 
 * @export
 * @interface TrackPurchaseResponse
 */
export interface TrackPurchaseResponse {
    /**
     * 
     * @type {string}
     * @memberof TrackPurchaseResponse
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface TrackUserAdditionalFields
 */
export interface TrackUserAdditionalFields {
    /**
     * 
     * @type {string}
     * @memberof TrackUserAdditionalFields
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackUserAdditionalFields
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackUserAdditionalFields
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackUserAdditionalFields
     */
    'quizUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackUserAdditionalFields
     */
    'healthGoal'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrackUserAdditionalFields
     */
    'menoscaleScienceConsent'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TrackUserAdditionalFields
     */
    'menoscaleScore'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrackUserAdditionalFields
     */
    'menoscaleMainSymptoms'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TrackUserRequest
 */
export interface TrackUserRequest {
    /**
     * 
     * @type {string}
     * @memberof TrackUserRequest
     */
    'externalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TrackUserRequest
     */
    'customEventName': TrackUserRequestCustomEventNameEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TrackUserRequest
     */
    'customEventProperties'?: { [key: string]: object; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrackUserRequest
     */
    'marketingConsent': boolean;
    /**
     * 
     * @type {TrackUserRequestAdditionalFields}
     * @memberof TrackUserRequest
     */
    'additionalFields'?: TrackUserRequestAdditionalFields | null;
}

export const TrackUserRequestCustomEventNameEnum = {
    quiz_email_submitted: 'quiz-email-submitted',
    quiz_us_waitlist_email_submitted: 'quiz-us-waitlist-email-submitted',
    quiz_gb_waitlist_email_submitted: 'quiz-gb-waitlist-email-submitted',
    waitlist_email_submitted: 'waitlist-email-submitted',
    nutrition_newsletter_email_submitted: 'nutrition-newsletter-email-submitted',
    freeguide_email_submitted: 'freeguide-email-submitted',
    gutshot_email_submitted: 'gutshot-email-submitted',
    whitepaper_email_submitted: 'whitepaper-email-submitted',
    box_preparation_requested: 'box-preparation-requested',
    box_dispatched: 'box-dispatched',
    box_arrived: 'box-arrived',
    box_scanned: 'box-scanned',
    start_date_selected: 'start-date-selected',
    referral_signup: 'referral-signup',
    referral_complete: 'referral-complete',
    d30_gb_no_stock_waitlist_email_submitted: 'd30-gb-no-stock-waitlist-email-submitted',
    d30_us_no_stock_waitlist_email_submitted: 'd30-us-no-stock-waitlist-email-submitted',
    d30_non_purchase_country_waitlist_email_submitted: 'd30-non-purchase-country-waitlist-email-submitted',
    d30_non_gb_waitlist_email_submitted: 'd30-non-gb-waitlist-email-submitted',
    user_results_shipped: 'user-results-shipped',
    user_metabolic_results_shipped: 'user-metabolic-results-shipped',
    user_microbiome_results_shipped: 'user-microbiome-results-shipped',
    menoscale_email_submitted: 'menoscale-email-submitted'
} as const;

export type TrackUserRequestCustomEventNameEnum = typeof TrackUserRequestCustomEventNameEnum[keyof typeof TrackUserRequestCustomEventNameEnum];

/**
 * @type TrackUserRequestAdditionalFields
 * @export
 */
export type TrackUserRequestAdditionalFields = TrackUserAdditionalFields;

/**
 * 
 * @export
 * @interface TrackUserResponse
 */
export interface TrackUserResponse {
    /**
     * 
     * @type {string}
     * @memberof TrackUserResponse
     */
    'email': string;
}

/**
 * RapideApi - axios parameter creator
 * @export
 */
export const RapideApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rapide/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication open-id required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "open-id", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RapideApi - functional programming interface
 * @export
 */
export const RapideApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RapideApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async info(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.info(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RapideApi.info']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RapideApi - factory interface
 * @export
 */
export const RapideApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RapideApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info(options?: any): AxiosPromise<string> {
            return localVarFp.info(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RapideApi - object-oriented interface
 * @export
 * @class RapideApi
 * @extends {BaseAPI}
 */
export class RapideApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RapideApi
     */
    public info(options?: RawAxiosRequestConfig) {
        return RapideApiFp(this.configuration).info(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Track a purchase event made by a new or existing user.
         * @param {TrackPurchaseRequest} trackPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPurchase: async (trackPurchaseRequest: TrackPurchaseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackPurchaseRequest' is not null or undefined
            assertParamExists('trackPurchase', 'trackPurchaseRequest', trackPurchaseRequest)
            const localVarPath = `/public/users/track-purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackPurchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track a new or existing user with an email.
         * @param {TrackUserRequest} trackUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackUser: async (trackUserRequest: TrackUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackUserRequest' is not null or undefined
            assertParamExists('trackUser', 'trackUserRequest', trackUserRequest)
            const localVarPath = `/public/users/track`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Track a purchase event made by a new or existing user.
         * @param {TrackPurchaseRequest} trackPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackPurchase(trackPurchaseRequest: TrackPurchaseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackPurchaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackPurchase(trackPurchaseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.trackPurchase']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Track a new or existing user with an email.
         * @param {TrackUserRequest} trackUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackUser(trackUserRequest: TrackUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackUser(trackUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.trackUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Track a purchase event made by a new or existing user.
         * @param {TrackPurchaseRequest} trackPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPurchase(trackPurchaseRequest: TrackPurchaseRequest, options?: any): AxiosPromise<TrackPurchaseResponse> {
            return localVarFp.trackPurchase(trackPurchaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track a new or existing user with an email.
         * @param {TrackUserRequest} trackUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackUser(trackUserRequest: TrackUserRequest, options?: any): AxiosPromise<TrackUserResponse> {
            return localVarFp.trackUser(trackUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Track a purchase event made by a new or existing user.
     * @param {TrackPurchaseRequest} trackPurchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public trackPurchase(trackPurchaseRequest: TrackPurchaseRequest, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).trackPurchase(trackPurchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track a new or existing user with an email.
     * @param {TrackUserRequest} trackUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public trackUser(trackUserRequest: TrackUserRequest, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).trackUser(trackUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



